

import { Component, Prop, Vue } from "vue-property-decorator";

import { CONTENT_TYPE, ContentItem } from "@/types";

import { storage } from "@/firebase";
import { ref, getDownloadURL } from 'firebase/storage'
//@ts-ignore
import pdf from 'vue-pdf'

import { saveAs } from 'file-saver';
import axios from 'axios';

@Component({
  components: {
    pdf
  }
})
export default class ContentViewer extends Vue {
  @Prop({ required: true }) item!: ContentItem;

  // used in PDF

  pageCount = 0;
  task = null as any;


  openTime = window.performance.now();
  sentComplete = false;

  loading = {
    download: false,
  }

  get videoId() {
    if (this.item) {
      return this.item.property.match(/(?:v=)([\w-]{11})/)?.[1]
    }
    return ''
  }

  downloadUrl = null as string | null;

  async downloadFile() {
    const fileRef = ref(storage, `content-items/${this.item.id}`)
    const url = await getDownloadURL(fileRef);
    axios
      .get(url, { responseType: 'blob' })
      .then(response => {
        saveAs(response.data, this.item.title + '.pdf');
        this.loading.download = false;
      })
  }


  async handleTimeUpdate(event: any) {
    const video = event.target;
    const watchedPercentage = (video.currentTime / video.duration) * 100;
    const eightyPercentDuration = video.duration * 0.8 * 1000;

    this.$emit('video-duration', video.duration)

    if (!this.sentComplete) {
      if (watchedPercentage >= 80) {
        const currentTime = window.performance.now();
        if ((currentTime - this.openTime) > eightyPercentDuration) {
          this.$emit('complete')
          this.sentComplete = true;
        } else {
          console.log('page time not ok')
        }
      }
    }
  }

  async mounted() {
    const fileRef = ref(storage, `content-items/${this.item.id}`)
    const url = await getDownloadURL(fileRef);
    if (this.item.type === CONTENT_TYPE.PDF || this.item.type === CONTENT_TYPE.VIDEO) {
      this.downloadUrl = url;
    }

    if (this.item.type === CONTENT_TYPE.PDF) {
      this.task = pdf.createLoadingTask(url);
      this.task.promise.then((pdf: any) => {
        this.pageCount = pdf.numPages
      })
    }


    if (this.item.type === CONTENT_TYPE.PDF || this.item.type === CONTENT_TYPE.YOUTUBE_VIDEO) {
      this.$emit('complete')
    }
  }
}

